package com.cordylussoftware.nocta.components.elements.text

import androidx.compose.runtime.Composable
import com.cordylussoftware.nocta.utils.Resources
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.px

@Composable
fun MediumClickableText(modifier: Modifier = Modifier, text: String, enabled: Boolean,
                        onClick: () -> Unit) {
    SpanText(
        text = text,
        modifier = modifier
            .fontFamily(Resources.Fonts.ROBOTO_REGULAR)
            .color(if (enabled) Resources.Theme.BLUE.color else Resources.Theme.BLACK.color)
            .fontSize(16.px)
            .fontWeight(FontWeight.Normal)
            .textAlign(TextAlign.Start)
            .then(if (enabled) Modifier .textDecorationLine(textDecorationLine = TextDecorationLine.Underline) else Modifier)
            .then(if (enabled) Modifier.onClick { onClick() } else Modifier)
            .then(if (enabled) Modifier.cursor(Cursor.Pointer) else Modifier)
    )
}