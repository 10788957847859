package com.cordylussoftware.nocta.components.elements

import androidx.compose.runtime.Composable
import com.cordylussoftware.nocta.utils.Resources
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.objectFit
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.navigation.Link
import org.jetbrains.compose.web.css.px

@Composable
fun GooglePlayButton(modifier: Modifier = Modifier) {
    Link(path = Resources.Links.GOOGLE_PLAY) {
        Image(
            modifier = modifier
                .width(218.px)
                .objectFit(ObjectFit.Cover),
            src = Resources.Image.GOOGLE_PLAY_BADGE
        )
    }
}