package com.cordylussoftware.nocta.components.elements.text

import androidx.compose.runtime.Composable
import com.cordylussoftware.nocta.utils.Resources
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.px

@Composable
fun HeaderText(modifier: Modifier = Modifier, text: String) {
    SpanText(
        text = text,
        modifier = modifier
            .fontFamily(Resources.Fonts.ROBOTO_REGULAR)
            .color(Resources.Theme.BLACK.color)
            .fontSize(24.px)
            .fontWeight(FontWeight.Bold)
            .textAlign(TextAlign.Start)
    )
}