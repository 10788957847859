package com.cordylussoftware.nocta.components

import Res
import androidx.compose.runtime.Composable
import com.cordylussoftware.nocta.components.elements.*
import com.cordylussoftware.nocta.components.elements.text.LargeHeaderText
import com.cordylussoftware.nocta.components.elements.text.LargeIncardText
import com.cordylussoftware.nocta.components.elements.text.MediumIncardText
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.height
import org.jetbrains.compose.web.css.px

@Composable
fun DescriptionCard(modifier: Modifier = Modifier) {
    Column(modifier = modifier) {
        LanguageSwitcher()
        Box(modifier = Modifier.height(16.px))
        LargeHeaderText(text = Res.string.card_title)
        Box(modifier = Modifier.height(4.px))
        LargeIncardText(text = Res.string.card_subtitle)
        Box(modifier = Modifier.height(32.px))
        MediumIncardText(text = Res.string.card_text)
        Box(modifier = Modifier.height(12.px))
        LinksRow()
        Box(modifier = Modifier.height(36.px))
        GooglePlayButton()
    }
}