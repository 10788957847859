package com.cordylussoftware.nocta.components

import androidx.compose.runtime.Composable
import com.cordylussoftware.nocta.components.elements.LinksWithDesctiptionsRow
import com.cordylussoftware.nocta.utils.Resources
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import org.jetbrains.compose.web.css.px

@Composable
fun HorizontalScreen(modifier: Modifier = Modifier) {
    Column(
        modifier = modifier
            .background(Resources.Theme.LIGHT_BLUE.color),
//        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {
        Row(modifier = Modifier.fillMaxWidth().height(576.px),
            horizontalArrangement = Arrangement.Center){
            Image(
                modifier = Modifier.margin(top = 65.px, right = 40.px).width(440.px).height(503.px)
                    .objectFit(ObjectFit.Cover),
                src = Resources.Image.NOCTA_SCREENSHOTS
            )
            DescriptionCard(modifier = Modifier.width(427.px).margin (top = 128.px, left = 12.px))
        }
        Box(modifier = Modifier.height(24.px))
        LinksWithDesctiptionsRow(modifier = Modifier.fillMaxWidth())
    }

}