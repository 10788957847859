package com.cordylussoftware.nocta.components.elements

import androidx.compose.runtime.Composable
import com.cordylussoftware.nocta.LOCALE_KEY
import com.cordylussoftware.nocta.components.elements.text.InCardText
import com.cordylussoftware.nocta.components.elements.text.MediumClickableText
import com.cordylussoftware.nocta.components.elements.text.MediumLinkText
import com.cordylussoftware.nocta.utils.Resources
import com.cordylussoftware.nocta.utils.locales
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.margin
import kotlinx.browser.localStorage
import kotlinx.browser.window
import org.jetbrains.compose.web.css.px
@Composable
fun LanguageSwitcher(modifier: Modifier = Modifier) {
    Row(
        modifier = modifier,
        horizontalArrangement = Arrangement.Start
    ) {
        MediumClickableText(text = Res.string.language_switcher_english, enabled = localStorage.getItem(LOCALE_KEY) == "ru"){
            localStorage.setItem(LOCALE_KEY, "en")
            window.location.reload()
        }
        InCardText(text = Res.string.language_switcher_slash, modifier = Modifier.margin(leftRight = 8.px))
        MediumClickableText(text = Res.string.language_switcher_russian, enabled = localStorage.getItem(LOCALE_KEY) == "en"){
            localStorage.setItem(LOCALE_KEY, "ru")
            window.location.reload()
        }
    }
}