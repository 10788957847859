package com.cordylussoftware.nocta.components.elements

import androidx.compose.runtime.Composable
import com.cordylussoftware.nocta.components.elements.text.InCardText
import com.cordylussoftware.nocta.components.elements.text.MediumLinkText
import com.cordylussoftware.nocta.utils.Resources
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.margin
import org.jetbrains.compose.web.css.px
@Composable
fun LinksRow(modifier: Modifier = Modifier) {
    Row(
        modifier = modifier,
        horizontalArrangement = Arrangement.Start
    ) {
        MediumLinkText(text = Res.string.link_name_google_play, link = Resources.Links.GOOGLE_PLAY)
        InCardText(text = Res.string.useful_links_link_divider, modifier = Modifier.margin(leftRight = 12.px))
        MediumLinkText(text = Res.string.link_name_4pda, link = Resources.Links.PDA4)
        InCardText(text = Res.string.useful_links_link_divider, modifier = Modifier.margin(leftRight = 12.px))
        MediumLinkText(text = Res.string.link_name_telegram, link = Resources.Links.TELEGRAM)
    }
}