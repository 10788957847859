package com.cordylussoftware.nocta.components.elements

import androidx.compose.runtime.Composable
import com.cordylussoftware.nocta.utils.Resources
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.width
import org.jetbrains.compose.web.css.px

@Composable
fun LinksWithDesctiptionsRow(modifier: Modifier = Modifier) {
    Row(
        modifier = modifier,
        horizontalArrangement = Arrangement.Center
    ) {
        LinkWithDescription(description = Res.string.link_description1, text = Res.string.useful_links_name1, link = Resources.Email.DEVELOPER)
        Box(modifier = Modifier.width(149.px))
        SupportLinks()
        Box(modifier = Modifier.width(149.px))
        DesignLinks()
        Box(modifier = Modifier.width(149.px))
        LinkWithDescription(description = Res.string.link_description4, text = Res.string.useful_links_name3, link = Resources.Email.INNA)

    }
}