package com.cordylussoftware.nocta.components.elements.text

import androidx.compose.runtime.Composable
import com.cordylussoftware.nocta.utils.Resources
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.px

@Composable
fun MediumLinkText(modifier: Modifier = Modifier, text: String, link: String = "") {
    Link(path = link) {
        SpanText(
            text = text,
            modifier = modifier
                .fontFamily(Resources.Fonts.ROBOTO_REGULAR)
                .color(Resources.Theme.BLUE.color)
                .fontSize(16.px)
                .fontWeight(FontWeight.Normal)
                .textAlign(TextAlign.Start)
                .textDecorationLine(textDecorationLine = TextDecorationLine.Underline)
        )
    }
}