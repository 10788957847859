package com.cordylussoftware.nocta.components.elements

import androidx.compose.runtime.Composable
import com.cordylussoftware.nocta.components.elements.text.InCardText
import com.cordylussoftware.nocta.components.elements.text.SmallIncardText
import com.cordylussoftware.nocta.components.elements.text.SmallLinkText
import com.cordylussoftware.nocta.utils.Resources
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import org.jetbrains.compose.web.css.px

@Composable
fun DesignLinks(modifier: Modifier = Modifier) {
    Column(modifier = modifier, horizontalAlignment = Alignment.CenterHorizontally) {
        SmallIncardText(text = Res.string.link_description3)
        Box(modifier = Modifier.height(4.px))
        Row {
            SmallLinkText(text = Res.string.useful_links_name21, link = Resources.Links.BEHANCE)
            InCardText(text = Res.string.useful_links_link_divider, modifier = Modifier.margin(leftRight = 12.px))
            SmallLinkText(text = Res.string.useful_links_name22, link = Resources.Email.YAR)
        }

    }
}