package strings

import io.github.skeptick.libres.strings.PluralForms
import io.github.skeptick.libres.strings.getCurrentLanguageCode
import kotlin.String
import kotlin.collections.Map

public object ResStrings {
  private val baseLocale: StringsEn = StringsEn

  private val locales: Map<String, Strings> = mapOf("en" to StringsEn, "ru" to StringsRu)

  public val page_title: String
    get() = locales[getCurrentLanguageCode()]?.page_title ?: baseLocale.page_title

  public val app_description: String
    get() = locales[getCurrentLanguageCode()]?.app_description ?: baseLocale.app_description

  public val link_name_google_play: String
    get() = locales[getCurrentLanguageCode()]?.link_name_google_play ?:
        baseLocale.link_name_google_play

  public val link_name_4pda: String
    get() = locales[getCurrentLanguageCode()]?.link_name_4pda ?: baseLocale.link_name_4pda

  public val link_name_telegram: String
    get() = locales[getCurrentLanguageCode()]?.link_name_telegram ?: baseLocale.link_name_telegram

  public val useful_links_link_divider: String
    get() = locales[getCurrentLanguageCode()]?.useful_links_link_divider ?:
        baseLocale.useful_links_link_divider

  public val language_switcher_slash: String
    get() = locales[getCurrentLanguageCode()]?.language_switcher_slash ?:
        baseLocale.language_switcher_slash

  public val language_switcher_english: String
    get() = locales[getCurrentLanguageCode()]?.language_switcher_english ?:
        baseLocale.language_switcher_english

  public val language_switcher_russian: String
    get() = locales[getCurrentLanguageCode()]?.language_switcher_russian ?:
        baseLocale.language_switcher_russian

  public val useful_links_title: String
    get() = locales[getCurrentLanguageCode()]?.useful_links_title ?: baseLocale.useful_links_title

  public val useful_links_subtitle1: String
    get() = locales[getCurrentLanguageCode()]?.useful_links_subtitle1 ?:
        baseLocale.useful_links_subtitle1

  public val useful_links_subtitle2: String
    get() = locales[getCurrentLanguageCode()]?.useful_links_subtitle2 ?:
        baseLocale.useful_links_subtitle2

  public val useful_links_subtitle3: String
    get() = locales[getCurrentLanguageCode()]?.useful_links_subtitle3 ?:
        baseLocale.useful_links_subtitle3

  public val useful_links_subtitle4: String
    get() = locales[getCurrentLanguageCode()]?.useful_links_subtitle4 ?:
        baseLocale.useful_links_subtitle4

  public val useful_links_name1: String
    get() = locales[getCurrentLanguageCode()]?.useful_links_name1 ?: baseLocale.useful_links_name1

  public val useful_links_name21: String
    get() = locales[getCurrentLanguageCode()]?.useful_links_name21 ?: baseLocale.useful_links_name21

  public val useful_links_name22: String
    get() = locales[getCurrentLanguageCode()]?.useful_links_name22 ?: baseLocale.useful_links_name22

  public val useful_links_name3: String
    get() = locales[getCurrentLanguageCode()]?.useful_links_name3 ?: baseLocale.useful_links_name3

  public val useful_links_name41: String
    get() = locales[getCurrentLanguageCode()]?.useful_links_name41 ?: baseLocale.useful_links_name41

  public val useful_links_name42: String
    get() = locales[getCurrentLanguageCode()]?.useful_links_name42 ?: baseLocale.useful_links_name42

  public val card_title: String
    get() = locales[getCurrentLanguageCode()]?.card_title ?: baseLocale.card_title

  public val card_subtitle: String
    get() = locales[getCurrentLanguageCode()]?.card_subtitle ?: baseLocale.card_subtitle

  public val card_text: String
    get() = locales[getCurrentLanguageCode()]?.card_text ?: baseLocale.card_text

  public val link_description1: String
    get() = locales[getCurrentLanguageCode()]?.link_description1 ?: baseLocale.link_description1

  public val link_description2: String
    get() = locales[getCurrentLanguageCode()]?.link_description2 ?: baseLocale.link_description2

  public val link_description3: String
    get() = locales[getCurrentLanguageCode()]?.link_description3 ?: baseLocale.link_description3

  public val link_description4: String
    get() = locales[getCurrentLanguageCode()]?.link_description4 ?: baseLocale.link_description4
}
