package com.cordylussoftware.nocta.components.elements.text

import androidx.compose.runtime.Composable
import com.cordylussoftware.nocta.utils.Resources
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.navigation.Link

@Composable
fun SmallLinkText(modifier: Modifier = Modifier, text: String, link: String = "") {
    Link(path = link) {
        SmallIncardText(
            modifier = modifier
                .textDecorationLine(textDecorationLine = TextDecorationLine.Underline),
            text = text,
            textColor = Resources.Theme.BLUE.color
        )
    }
}