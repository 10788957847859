package com.cordylussoftware.nocta.components

import androidx.compose.runtime.Composable
import com.cordylussoftware.nocta.components.elements.*
import com.cordylussoftware.nocta.components.elements.text.HeaderText
import com.cordylussoftware.nocta.components.elements.text.InCardText
import com.cordylussoftware.nocta.components.elements.text.MediumLinkText
import com.cordylussoftware.nocta.utils.Resources
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import org.jetbrains.compose.web.css.px

@Composable
fun VerticalScreen(modifier: Modifier = Modifier) {
    Column(
        modifier = modifier
            .background(Resources.Theme.LIGHT_BLUE.color)
            .padding(32.px),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.Start,
    ) {
        Image(
            modifier = Modifier.fillMaxSize()
                .objectFit(ObjectFit.Cover),
            src = Resources.Image.NOCTA_SCREENSHOTS
        )
        Box(modifier = Modifier.height(30.px))
        LanguageSwitcher()
        Box(modifier = Modifier.height(12.px))
        HeaderText(text = Res.string.page_title)
        Box(modifier = Modifier.height(16.px))
        InCardText(text = Res.string.app_description)
        Box(modifier = Modifier.height(8.px))
        LinksRow(modifier = Modifier.fillMaxWidth())
        Box(modifier = Modifier.height(64.px))
        HeaderText(modifier = Modifier, text = Res.string.useful_links_title)
        Box(modifier = Modifier.height(32.px))
        InCardText(modifier = Modifier, text = Res.string.useful_links_subtitle1)
        MediumLinkText(
            modifier = Modifier.margin(top = 4.px),
            text = Res.string.useful_links_name1,
            link = Resources.Email.DEVELOPER
        )
        Box(modifier = Modifier.height(24.px))
        InCardText(modifier = Modifier, text = Res.string.useful_links_subtitle2)
        Row(modifier = Modifier.margin(top = 4.px).fillMaxWidth()) {
            MediumLinkText(text = Res.string.useful_links_name21, link = Resources.Links.BEHANCE)
            InCardText(text = Res.string.useful_links_link_divider, modifier = Modifier.margin(leftRight = 12.px))
            MediumLinkText(text = Res.string.useful_links_name22, link = Resources.Email.YAR)
        }
        Box(modifier = Modifier.height(24.px))
        InCardText(modifier = Modifier, text = Res.string.useful_links_subtitle3)
        MediumLinkText(
            modifier = Modifier.margin(top = 4.px),
            text = Res.string.useful_links_name3,
            link = Resources.Email.INNA
        )
        Box(modifier = Modifier.height(24.px))
        InCardText(modifier = Modifier, text = Res.string.useful_links_subtitle4)
        Row(modifier = Modifier.margin(top = 4.px).fillMaxWidth()) {
            MediumLinkText(text = Res.string.useful_links_name41, link = Resources.Links.MONEY_CLOUD)
            InCardText(text = Res.string.useful_links_link_divider, modifier = Modifier.margin(leftRight = 12.px))
            MediumLinkText(text = Res.string.useful_links_name42, link = Resources.Links.MONEY_YOOMONEY)
        }
        Box(modifier = Modifier.height(46.px))
        Box(modifier = Modifier.fillMaxSize(), contentAlignment = Alignment.Center) {
            GooglePlayButton()
        }

    }

}