package com.cordylussoftware.nocta.utils

import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.rgba
import Res

val Res.locales get() = listOf("en", "ru")
object Resources {
    enum class Theme(val color: CSSColorValue){
        LIGHT_BLUE(color = rgba(239, 247, 255, 1)),
        BLACK(color = rgba(0, 0, 0, 1)),
        BLUE(color = rgba(44, 39, 255, 1))
    }
    object Image {
        const val NOCTA_SCREENSHOTS = "ic_nocta_screenshots.png"
        const val GOOGLE_PLAY_BADGE = "google-play-badge.png"
    }
//    object String {
//        const val PAGE_TITLE = "Nocta Pro — продвинутый трекер подписок"
//        const val APP_DESCRIPTION = "Nocta Pro — трекер платных подписок на различные сервисы и игры, который покажет детальную статистику, будущие расходы и даст рекомендации для оптимизации трат. "
//        const val LINK_NAME_GOOGLE_PLAY = "Google Play"
//        const val LINK_NAME_4PDA = "4PDA"
//        const val LINK_NAME_TELEGRAM = "Telegram"
//        const val USEFUL_LINKS_LINK_DIVIDER = "|"
//
//        const val USEFUL_LINKS_TITLE = "Полезные ссылки:"
//        const val USEFUL_LINKS_SUBTITLE1 = "Написать разработчику (предложить идею или сообщить об ошибке):"
//        const val USEFUL_LINKS_LINK_NAME1 = "cordylus.software@gmail.com"
//        const val USEFUL_LINKS_SUBTITLE2 = "Дизайн продукта и интерфейса:"
//        const val USEFUL_LINKS_LINK_NAME21 = "Behance"
//        const val USEFUL_LINKS_LINK_NAME22 = "yar@1way.cc"
//        const val USEFUL_LINKS_SUBTITLE3 = "Разработчик (код и реализация):"
//        const val USEFUL_LINKS_LINK_NAME3 = "t0inna@yandex.ru"
//        const val USEFUL_LINKS_SUBTITLE4 = "Поддержать разработку приложений:"
//        const val USEFUL_LINKS_LINK_NAME41 = "Любой картой"
//        const val USEFUL_LINKS_LINK_NAME42 = "Юмани"
//
//        const val CARD_TITLE = "Nocta Pro"
//        const val CARD_SUBTITLE = "Ваш новый трекер подписок!"
//        const val CARD_TEXT = "Nocta Pro — трекер платных подписок на различные сервисы и игры, который покажет детальную статистику, будущие расходы и даст рекомендации для оптимизации трат. "
//        const val LINK_DESCRIPTION1 = "Помощь и поддержка:"
//        const val LINK_DESCRIPTION2 = "Поддержать разработку:"
//        const val LINK_DESCRIPTION3 = "Дизайн продукта:"
//        const val LINK_DESCRIPTION4 = "Разработка и код:"
//    }
    object Links {
        const val GOOGLE_PLAY = "https://play.google.com/store/apps/details?id=com.cordylussoftware.nocta"
        const val PDA4 = "https://4pda.to/forum/index.php?showtopic=1076138"
        const val TELEGRAM = "https://t.me/noctatracker"
        const val MONEY_CLOUD = "https://pay.cloudtips.ru/p/d2d557bb"
        const val MONEY_YOOMONEY = "https://yoomoney.ru/to/410011054409321"
        const val BEHANCE = "https://www.behance.net/raevsky"
    }
    object Email {
        const val YAR = "mailto:yar@1way.cc"
        const val INNA = "mailto:t0inna@yandex.ru"
        const val DEVELOPER = "mailto:cordylus.software@gmail.com"
    }
    object Dimens {
        const val IMAGE_SIZE = 292
        const val PADDING_HORIZONTAL = 34
        const val MAX_CARD_WIDTH = 600
        const val MAX_CARD_HEIGHT = 700
    }

    object Fonts {
        const val ROBOTO_MEDIUM = "RobotoMedium"
        const val ROBOTO_REGULAR = "RobotoRegular"
    }
}