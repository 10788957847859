package com.cordylussoftware.nocta.components.elements.text

import androidx.compose.runtime.Composable
import com.cordylussoftware.nocta.utils.Resources
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.px

@Composable
fun SmallIncardText(modifier: Modifier = Modifier, text: String, textColor: CSSColorValue = Resources.Theme.BLACK.color) {
    SpanText(
        text = text,
        modifier = modifier
            .fontFamily(Resources.Fonts.ROBOTO_REGULAR)
            .color(textColor)
            .fontSize(14.px)
            .textAlign(TextAlign.Start)
            .lineHeight(16.41.px)
    )
}