package com.cordylussoftware.nocta.pages

import androidx.compose.runtime.*
import com.cordylussoftware.nocta.components.NoctaCard
import com.cordylussoftware.nocta.utils.Resources
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.background
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.core.Page

@Page
@Composable
fun HomePage() {
    Box(Modifier.fillMaxSize().background(Resources.Theme.LIGHT_BLUE.color),
        contentAlignment = Alignment.Center) {
        NoctaCard(modifier = Modifier.background(Resources.Theme.LIGHT_BLUE.color))
    }
}
