package com.cordylussoftware.nocta.components.elements

import androidx.compose.runtime.Composable
import com.cordylussoftware.nocta.components.elements.text.SmallIncardText
import com.cordylussoftware.nocta.components.elements.text.SmallLinkText
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.height
import org.jetbrains.compose.web.css.px

@Composable
fun LinkWithDescription(modifier: Modifier = Modifier,description: String, text: String, link: String) {
    Column(modifier = modifier, horizontalAlignment = Alignment.CenterHorizontally) {
        SmallIncardText(text = description)
        Box(modifier = Modifier.height(4.px))
        SmallLinkText(text = text, link = link)
    }
}