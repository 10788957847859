package strings

import io.github.skeptick.libres.strings.PluralForms
import io.github.skeptick.libres.strings.getCurrentLanguageCode
import kotlin.String

public object StringsEn : Strings {
  override val page_title: String = "Nocta Pro: Your new subscription tracker!"

  override val app_description: String =
      "Nocta Pro is a free tracker for paid subscriptions to different services and games that will show detailed statistics, future expenses and give tips to optimize spending"

  override val link_name_google_play: String = "Google Play"

  override val link_name_4pda: String = "4PDA"

  override val link_name_telegram: String = "Telegram"

  override val useful_links_link_divider: String = "|"

  override val language_switcher_slash: String = "/"

  override val language_switcher_english: String = "English"

  override val language_switcher_russian: String = "Русский"

  override val useful_links_title: String = "Useful links:"

  override val useful_links_subtitle1: String =
      "Contact the developer (suggest an idea or report a bug):"

  override val useful_links_subtitle2: String = "Product design:"

  override val useful_links_subtitle3: String = "App development:"

  override val useful_links_subtitle4: String = "Support us:"

  override val useful_links_name1: String = "cordylus.software@gmail.com"

  override val useful_links_name21: String = "Behance"

  override val useful_links_name22: String = "yar@1way.cc"

  override val useful_links_name3: String = "send.to.foenix@gmail.com"

  override val useful_links_name41: String = "Tinkoff"

  override val useful_links_name42: String = "YooMoney"

  override val card_title: String = "Nocta Pro"

  override val card_subtitle: String = "Your new subscription tracker!"

  override val card_text: String =
      "Nocta Pro is a free tracker for paid subscriptions to different services and games that will show detailed statistics, future expenses and give tips to optimize spending"

  override val link_description1: String = "Help and support:"

  override val link_description2: String = "Support us:"

  override val link_description3: String = "Product Design:"

  override val link_description4: String = "App Developer:"
}
