package strings

import io.github.skeptick.libres.strings.PluralForms
import io.github.skeptick.libres.strings.getCurrentLanguageCode
import kotlin.String

public object StringsRu : Strings {
  override val page_title: String = "Nocta Pro — продвинутый трекер подписок"

  override val app_description: String =
      "Nocta Pro — трекер платных подписок на различные сервисы и игры, который покажет детальную статистику, будущие расходы и даст рекомендации для оптимизации трат"

  override val link_name_google_play: String = "Google Play"

  override val link_name_4pda: String = "4PDA"

  override val link_name_telegram: String = "Telegram"

  override val useful_links_link_divider: String = "|"

  override val language_switcher_slash: String = "/"

  override val language_switcher_english: String = "English"

  override val language_switcher_russian: String = "Русский"

  override val useful_links_title: String = "Полезные ссылки:"

  override val useful_links_subtitle1: String =
      "Написать разработчику (предложить идею или сообщить об ошибке):"

  override val useful_links_subtitle2: String = "Дизайн продукта и интерфейса:"

  override val useful_links_subtitle3: String = "Разработчик (код и реализация):"

  override val useful_links_subtitle4: String = "Поддержать разработку приложений:"

  override val useful_links_name1: String = "cordylus.software@gmail.com"

  override val useful_links_name21: String = "Behance"

  override val useful_links_name22: String = "yar@1way.cc"

  override val useful_links_name3: String = "t0inna@yandex.ru"

  override val useful_links_name41: String = "Любой картой"

  override val useful_links_name42: String = "Юмани"

  override val card_title: String = "Nocta Pro"

  override val card_subtitle: String = "Ваш новый трекер подписок!"

  override val card_text: String =
      "Nocta Pro — трекер платных подписок на различные сервисы и игры, который покажет детальную статистику, будущие расходы и даст рекомендации для оптимизации трат"

  override val link_description1: String = "Помощь и поддержка:"

  override val link_description2: String = "Поддержать разработку:"

  override val link_description3: String = "Дизайн продукта:"

  override val link_description4: String = "Разработка и код:"
}
