package com.cordylussoftware.nocta.components

import androidx.compose.runtime.Composable
import com.cordylussoftware.nocta.utils.Resources
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun NoctaCard(modifier: Modifier = Modifier) {
    val breakpoint = rememberBreakpoint()
    if (breakpoint <= Breakpoint.LG) {
        Column(
            modifier = modifier
                .fillMaxWidth(
                    if (breakpoint <= Breakpoint.SM) 100.percent
                    else Resources.Dimens.MAX_CARD_WIDTH.px
                )
                .margin(topBottom = 54.px, leftRight = 32.px)
                .background(Resources.Theme.LIGHT_BLUE.color),
            horizontalAlignment = Alignment.CenterHorizontally

        ) {
            VerticalScreen(modifier = Modifier.fillMaxSize())
        }
    } else {
        HorizontalScreen(modifier = Modifier.fillMaxSize())
    }
}